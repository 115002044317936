import Vue from 'vue'
import VueFacebookPixel from 'vue-facebook-pixel'
import Vue2TouchEvents from 'vue2-touch-events'
import VueGtagPlugin from 'vue-gtag';

/* global $ */

import 'bootstrap'
import './assets/styles/bootstrap.scss'
import './assets/styles/main.scss'

import 'picturefill'

import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.use(Vue2TouchEvents)
Vue.use(VueGtagPlugin, {
  config: { id: 'G-4HSZCTKCWQ' },
});
Vue.use(VueFacebookPixel)

Vue.analytics.fbq.init('4496836010365590', {
  em: 'facebook@luvly.co.nz'
})

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')

$( document ).on( "click", ".toggle-menu-btn", function() {
  $('#header').toggleClass('open-menu');
});

$( document ).on( "scroll", function() {
      setTimeout(function(){
          let menu = document.querySelector('.menu');
          let scroll = window.scrollY;
          if(scroll<=775){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
          }else if(scroll>=775&&scroll<1685){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[0].classList.add('active');
          }else if(scroll>=1685&&scroll<2340){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[1].classList.add('active');
          }else if(scroll>=2430&&scroll<3235){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[2].classList.add('active');
          }else if(scroll>=3235&&scroll<4075){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[3].classList.add('active');
          }else if(scroll>=4075&&scroll<4890){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[4].classList.add('active');
          }else if(scroll>=4890){
            for (const child of menu.children) {
              child.classList.remove('active');
            }
            menu.children[5].classList.add('active');
          }
      },100);
});

$(document).on('click', '.wwab', function() {
  var rotate = 180;
  var elem = $(this).find('.btn-arrow-icon');

  //get the current degree
  var currentDegree = 0;
  if(typeof(elem.attr('data-degree')) != 'undefined') {
      currentDegree += parseInt(elem.attr('data-degree'), 10);
  }

  //calculate the new degree
  var newDegree = (currentDegree + rotate) % 360;


  //modify the elem css
  elem.css({ WebkitTransform: 'rotate(' + newDegree + 'deg)'});  
  elem.css({ '-moz-transform': 'rotate(' + newDegree + 'deg)'});
  elem.css('transform','rotate(' + newDegree + 'deg)');

  //store the degree for next time
  elem.attr('data-degree', newDegree);
})
